import { Button, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';

const OrderCard = ({ orders }) => {
  return (
    <Grid2
      direction='row'
      container
      sx={{
        textAlign: 'center',
        marginTop: 1,
      }}
    >
      <Grid2 item xs={3}>
        <Typography variant='h6'>Order Number</Typography>
      </Grid2>
      <Grid2 item xs={3}>
        <Typography variant='h6'>Order Date</Typography>
      </Grid2>
      <Grid2 item xs={3}>
        <Typography variant='h6'>Order Status</Typography>
      </Grid2>
      <Grid2 item xs={3}>
        <Typography variant='h6'>Order Total</Typography>
      </Grid2>
      <Grid2 item xs={4}>
        <Button variant='text'>View Order</Button>
      </Grid2>
      <Grid2 item xs={4}>
        <Button variant='text'>Cancel Order</Button>
      </Grid2>
      <Grid2 item xs={4}>
        <Button variant='text'>Return Items</Button>
      </Grid2>
      <Grid2 item xs={12}>
        <Divider />
      </Grid2>
    </Grid2>
  );
};

export default OrderCard;
