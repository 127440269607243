import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { red, orange } from '@mui/material/colors';

const getColumns = ({ handleItemOpenModal, handleQtyChange }) => [
  {
    header: 'Order Qty',
    accessorKey: 'suggested_qty',
    size: 75,
    Cell: ({ renderedCellValue, row }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant='outlined'
          size='small'
          value={renderedCellValue}
          onChange={(e) => handleQtyChange(row.original.item_number, e.target.value)}
          sx={{ maxWidth: '100%' }}
        />
      </Box>
    ),
  },
  {
    header: 'Your Qty',
    accessorKey: 'dealer_quantity',
    size: 75,
    Cell: ({ renderedCellValue }) => {
      let textColor;
      let backgroundColor;

      if (renderedCellValue == 0) {
        textColor = red[700];
        backgroundColor = red[100];
      }
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor,
            p: 1,
            borderRadius: 1,
          }}
        >
          <Typography variant='body1' sx={{ fontWeight: 'bold', color: textColor }}>
            {renderedCellValue}
          </Typography>
        </Box>
      );
    },
  },
  {
    header: 'Last Ordered',
    accessorKey: 'last_ordered_days',
    size: 100,
    sortingFn: (rowA, rowB) => {
      const a = rowA.original.last_ordered_days;
      const b = rowB.original.last_ordered_days;
      if (a === null && b === null) return 0;
      if (a === null) return 1; // Place nulls at the end
      if (b === null) return -1;
      return a - b;
    },
    Cell: ({ renderedCellValue }) => {
      let textColor;
      let backgroundColor;
      let displayValue;

      // 1) Handle null or undefined values
      if (renderedCellValue === null || renderedCellValue === undefined) {
        displayValue = 'No Sales Data';
        textColor = 'inherit';
      }
      // 2) Handle string 'N/A' specifically
      else if (renderedCellValue === 'N/A') {
        displayValue = 'N/A';
        textColor = 'inherit';
      }
      // 3) Then handle numeric cases
      else if (renderedCellValue == 0) {
        displayValue = 'Ordered Today';
        textColor = red[700];
        backgroundColor = red[100];
      } else if (renderedCellValue == 1) {
        displayValue = '1 Day Ago';
        textColor = red[700];
        backgroundColor = red[100];
      } else {
        displayValue = `${renderedCellValue} Days Ago`;

        if (renderedCellValue <= 3) {
          textColor = red[700];
          backgroundColor = red[100];
        } else if (renderedCellValue >= 4 && renderedCellValue <= 11) {
          textColor = orange[700];
          backgroundColor = orange[100];
        } else {
          textColor = 'inherit';
        }
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor,
            p: 1,
            borderRadius: 1,
            textAlign: 'center',
          }}
        >
          <Typography variant='body1' sx={{ fontWeight: 'bold', color: textColor }}>
            {displayValue}
          </Typography>
        </Box>
      );
    },
  },
  {
    header: 'SS Qty',
    accessorKey: 'quantity_on_hand',
    size: 75,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Type',
    accessorKey: 'item_type',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Item #',
    accessorKey: 'item_number',
    size: 75,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant='body1'
          onClick={() => handleItemOpenModal(renderedCellValue)}
          sx={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {renderedCellValue}
        </Typography>
      </Box>
    ),
  },
  {
    header: 'UPC #',
    accessorKey: 'upc',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Description',
    accessorKey: 'description',
    size: 300,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Cost',
    accessorKey: 'cost',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>${renderedCellValue}</Typography> {/* Add $ symbol */}
      </Box>
    ),
  },
];

export default getColumns;
