import React, { useEffect, useState } from 'react';
import { Button, Divider, TextField, Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const ReturnReasonTable = ({ selectedItems }) => {
  const theme = useTheme();

  const [returnQuantities, setReturnQuantities] = useState({});
  const [returnReasons, setReturnReasons] = useState({});
  const navigate = useNavigate();

  const handleSave = () => {
    navigate(0);
  };

  useEffect(() => {
    if (selectedItems) {
      setReturnQuantities(
        Object.entries(selectedItems).reduce((acc, [itemNumber, values]) => {
          if (values && values.QUANTITY != null) {
            acc[itemNumber] = values.QUANTITY;
          } else {
            acc[itemNumber] = 0;
          }
          return acc;
        }, {})
      );
    }
  }, [selectedItems]);

  const handleQuantityChange = (itemNumber) => (e) => {
    const newValue = parseInt(e.target.value, 10) || 0;
    setReturnQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemNumber]: newValue,
    }));
  };

  if (!selectedItems) {
    return;
  }

  return (
    <Grid container sx={{ maxHeight: '60vh', overflowY: 'auto', px: 2 }}>
      <Grid item xs={12}>
        <Typography variant='h5' sx={{ color: theme.palette.tsw.secondary }}>
          Return Details
        </Typography>
        <Divider sx={{ mx: 3, mt: 1, mb: 3, backgroundColor: theme.palette.tsw.tertiary }} />
      </Grid>
      <Grid item container direction='row' xs={12} spacing={1}>
        {Object.entries(selectedItems).map(([itemNumber, values]) => {
          return (
            <>
              <Grid item container direction='row' xs={12}>
                <Typography variant='h6' sx={{ width: '90%', color: theme.palette.tsw.secondary }}>
                  {values?.DESCRIPTION ?? ''}
                </Typography>
              </Grid>
              <Grid item container direction='row' xs={12}>
                <Grid item container direction='row' xs={3}>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ width: '90%' }}>
                      <b>Item #:</b> {values?.LINE_ITEMS ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ width: '90%' }}>
                      <b>Serial #:</b> {values?.SERIAL_NUMBERS ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ width: '90%' }}>
                      <b>UOM:</b> {values?.UOM ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ width: '90%' }}>
                      <b>Qty:</b> {values?.QUANTITY ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ width: '90%' }}>
                      <b>Price:</b> ${values?.PRICE ?? ''}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type='number'
                      label='Return Qty'
                      variant='outlined'
                      value={returnQuantities[itemNumber] ?? 0}
                      onChange={handleQuantityChange(itemNumber)}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: values?.QUANTITY ?? 0,
                          step: 1,
                        },
                      }}
                      sx={{ width: '90%', mt: 2 }}
                    />
                    <Button variant='outlined' sx={{ mt: 2 }}>
                      Upload Images
                    </Button>
                  </Grid>
                </Grid>
                <Grid item container direction='row' xs={9}>
                  <Grid item xs={12}>
                    <TextField
                      label='Return Reason'
                      fullWidth
                      required
                      multiline
                      rows={4}
                      value={returnReasons[itemNumber] ?? ''}
                      onChange={(e) =>
                        setReturnReasons({
                          ...returnReasons,
                          [itemNumber]: e.target.value,
                        })
                      }
                      sx={{
                        height: '100%',
                        '& .MuiInputBase-root': {
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        },
                        '& .MuiInputBase-input': {
                          flex: 1,
                          overflowWrap: 'break-word',
                          overflowY: 'auto',
                          paddingTop: '12px',
                          width: '100%',
                          whiteSpace: 'normal',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ mx: 3, my: 2, backgroundColor: theme.palette.tsw.tertiary }} />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant='contained'
          sx={{
            color: theme.palette.getContrastText(theme.palette.tsw.secondary),
            backgroundColor: theme.palette.tsw.secondary,
            display: 'flex',
          }}
          onClick={handleSave}
        >
          <Typography
            variant='button'
            sx={{
              color: theme.palette.getContrastText(theme.palette.tsw.secondary),
              textTransform: 'none',
              letterSpacing: '0.05em',
              fontSize: '1rem',
              fontWeight: 600,
            }}
          >
            Submit RMA Claim
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ReturnReasonTable;
