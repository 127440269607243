import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box } from '@mui/material';
import LoadingSpinner from '../_GlobalComponents/LoadingSpinner';
import Header from './Components/Header';
import CardList from './Components/CardList';

const Planograms = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const tokenFromUrl = pathParts[pathParts.length - 1];
    setToken(tokenFromUrl);
    setIsLoading(true);

    axios
      .post('/planograms', {
        customerNumber: tokenFromUrl,
        // customerNumber:
        //   'bjXHh-tzVF-lslq-QzgNu-vVVL-OTP-sNFz-TUJLK-oQm1q-ue6R-cxK-IHT-1BlRy-a0q-U60-Ufhw-Xjxg-fhgp-TvnlH-gq4y',
      })
      .then((response) => {
        if (response.data.result === 'success') {
          setData(response.data);
          console.log(response.data);
        } else if (response.data.result === 'empty') {
          // No items to display
          setData([]);
        } else if (response.data.result === 'error') {
          // Handle error case
          console.error('Error fetching data:', response.data.message);
          setData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box>
      <Header />
      {isLoading ? <LoadingSpinner isLoading={isLoading} /> : <CardList data={data} />}
    </Box>
  );
};

export default Planograms;
