import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import PlacedOrders from './Components/PlacedOrders';
import OrderSearch from './Components/OrderSearch';
import OrderFilter from './Components/OrderFilter';

const Orders = () => {
  return (
    <Grid2 spacing={2} sx={{ p: 2, justifyContent: 'center' }} container>
      <Grid2 item xs={6} sx={{ justifyContent: 'center', textAlign: 'center' }}>
        <OrderSearch />
      </Grid2>
      <Grid2 item xs={6}>
        <OrderFilter />
      </Grid2>
      <Grid2 container>
        <Grid2 item xs={12}>
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
          <PlacedOrders />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Orders;
