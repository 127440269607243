import React, { useState, useEffect } from 'react';
import {
  Modal,
  Paper,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import axios from 'axios';

const AddItemModal = ({ open, onClose, token }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [minQty, setMinQty] = useState('');
  const [maxQty, setMaxQty] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!debouncedSearchTerm.trim()) {
        setSearchResults([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const response = await axios.post('/suggestedOrders/getAddProductResults', {
          searchTerm: debouncedSearchTerm,
        });
        setSearchResults(response.data || []);
      } catch (err) {
        setError('Failed to fetch results. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [debouncedSearchTerm]);

  const handleAddItem = async () => {
    setAddLoading(true);
    try {
      await axios.post('/suggestedOrders/addUpdateItem', {
        customerNumber: token,
        itemNumber: selectedItem,
        itemMin: parseInt(minQty, 10),
        itemMax: parseInt(maxQty, 10),
      });

      // Clear the inputs and selection
      setSelectedItem(null);
      setMinQty('');
      setMaxQty('');
    } catch (err) {
      setError('Failed to add item. Please try again.');
    } finally {
      setAddLoading(false);
    }
  };

  const isAddButtonDisabled =
    !minQty ||
    !maxQty ||
    isNaN(minQty) ||
    isNaN(maxQty) ||
    parseInt(maxQty, 10) < parseInt(minQty, 10);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Paper
        sx={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '70%',
          maxHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          backgroundColor: 'white',
          boxShadow: 24,
          overflowY: 'auto',
          position: 'relative', // Make the modal container a positioned parent
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '8px', // Move above the modal content
            right: '8px', // Align to the top-right corner
            backgroundColor: 'white', // Add a white background for better contrast
            color: 'red', // Make the icon red
            boxShadow: 3, // Add a slight shadow for emphasis
            '&:hover': {
              backgroundColor: 'rgba(255, 0, 0, 0.1)', // Light red hover effect
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant='h6' id='modal-modal-title' sx={{ marginBottom: '20px' }}>
          Add Item
        </Typography>
        <TextField
          fullWidth
          variant='outlined'
          placeholder='Search for a product'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: '20px' }}
        />

        {loading && <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />}

        {error && (
          <Typography color='error' sx={{ marginBottom: '20px' }}>
            {error}
          </Typography>
        )}

        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          {!loading && searchResults.length > 0 && (
            <List>
              {searchResults.map((result, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    divider
                    onClick={() => setSelectedItem(result.ss_item)}
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      backgroundColor:
                        selectedItem === result.ss_item ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          sx={{ fontWeight: 'bold' }}
                        >{`SS Item #: ${result.ss_item} | MFR SKU: ${result.mfr_sku} | UPC: ${result.upc} | Brand: ${result.brand}`}</Typography>
                      }
                      secondary={result.description}
                      sx={{ marginBottom: selectedItem === result.ss_item ? '16px' : 0 }}
                    />
                  </ListItem>

                  {selectedItem === result.ss_item && (
                    <Box
                      sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <TextField
                        label='Min Qty'
                        type='number'
                        value={minQty}
                        onChange={(e) => setMinQty(e.target.value)}
                        size='small'
                        sx={{
                          width: '100px',
                        }}
                        disabled={addLoading}
                      />
                      <TextField
                        label='Max Qty'
                        type='number'
                        value={maxQty}
                        onChange={(e) => setMaxQty(e.target.value)}
                        size='small'
                        sx={{
                          width: '100px',
                        }}
                        disabled={addLoading}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{
                          flexShrink: 0,
                          height: '40px',
                        }}
                        onClick={handleAddItem}
                        disabled={isAddButtonDisabled || addLoading}
                      >
                        {addLoading ? <CircularProgress size={24} /> : 'Add Item'}
                      </Button>
                    </Box>
                  )}
                </React.Fragment>
              ))}
            </List>
          )}

          {!loading && !error && searchTerm.trim() && searchResults.length === 0 && (
            <Typography sx={{ textAlign: 'center' }}>No results found.</Typography>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddItemModal;
