import React from 'react';
import { Modal, Paper, Typography, Button, Box, CircularProgress } from '@mui/material';
import { WarningAmber as WarningIcon } from '@mui/icons-material';

const DeleteItemModal = ({ open, onClose, onDelete, loading }) => {
  return (
    <Modal
      open={open}
      onClose={!loading ? onClose : undefined} // Disable closing the modal while loading
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          padding: '20px',
          backgroundColor: 'white',
          boxShadow: 24,
          textAlign: 'center',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
          {loading ? (
            <CircularProgress color='error' />
          ) : (
            <WarningIcon color='error' sx={{ fontSize: 50 }} />
          )}
        </Box>
        {!loading && (
          <>
            <Typography variant='h6' id='modal-modal-title' sx={{ marginBottom: '16px' }}>
              Are you sure you want to delete this item?
            </Typography>
          </>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px' }}>
          <Button
            variant='contained'
            color='error'
            onClick={onDelete}
            disabled={loading} // Disable the button while loading
            fullWidth
          >
            Delete
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={onClose}
            disabled={loading} // Disable the button while loading
            fullWidth
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default DeleteItemModal;
