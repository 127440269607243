import { TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';

const OrderSearch = () => {
  return (
    <Grid2>
      <TextField
        id='outlined-basic'
        label='Search Orders'
        variant='outlined'
        sx={{ width: '40%' }}
      />
    </Grid2>
  );
};

export default OrderSearch;
