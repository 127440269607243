import { Box } from '@mui/material';
import React, { useState } from 'react';
import TabBar from './Components/TabBar';
import SettingsView from './Components/SettingsView';
import SuggestedOrderTable from './Components/SuggestedOrderTable/SuggestedOrderTable';

const SmartOrders = () => {
  const [view, setView] = useState('card');

  const handleViewChange = (newView) => {
    setView(newView);
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexShrink: 0 }}>
        <TabBar view={view} handleViewChange={handleViewChange} />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {view === 'card' && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <SuggestedOrderTable />
          </Box>
        )}
        {view === 'settings' && (
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
            <SettingsView />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SmartOrders;
