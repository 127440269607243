import React from 'react';
import { Box, Divider, Tab, Tabs } from '@mui/material';

const TabBar = ({ view, handleViewChange }) => {
  return (
    <Box mt={0} sx={{ padding: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={view}
          onChange={(e, value) => handleViewChange(value)}
          variant={'scrollable'}
          scrollButtons
          allowScrollButtonsMobile
          aria-label='Departments'
          sx={{ '& .MuiTab-root': { fontSize: '24px', mx: 2, whiteSpace: 'pre' } }}
        >
          <Tab label={'Smart Order'} value={'card'} />
          <Tab label={'Manage Min/Max'} value={'settings'} />
        </Tabs>
      </Box>
      <Divider sx={{ marginBottom: 0 }} />
    </Box>
  );
};

export default TabBar;
