import { Box, Typography, Select, MenuItem } from '@mui/material';
import React from 'react';

const OrderFilter = () => {
  return (
    <Box>
      <Typography variant='h6'>Filter By</Typography>
      <Select label='Filter By' sx={{ width: '40%' }} variant='outlined'>
        <MenuItem value='All'>All</MenuItem>
        <MenuItem value='Placed'>Placed</MenuItem>
        <MenuItem value='Shipped'>Shipped</MenuItem>
        <MenuItem value='Delivered'>Delivered</MenuItem>
        <MenuItem value='Cancelled'>Cancelled</MenuItem>
      </Select>
    </Box>
  );
};

export default OrderFilter;
