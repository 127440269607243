import React from 'react';
import OrderCard from './OrderCard';
import { Box } from '@mui/material';

const PlacedOrders = () => {
  return (
    <Box>
      <OrderCard />
    </Box>
  );
};

export default PlacedOrders;
